<template>
	<div>
		<div>
			<el-form :inline="true" ref="queryForm" :model="queryForm">
				<el-form-item>
					<el-select v-model="queryForm.type" placeholder="按操作类型查询">
						<el-option v-for="item in typeList" :key="item.id" :label="item.label" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-date-picker 
						v-model="queryForm.startTime" 
						type="date" 
						value-format="yyyy-MM-dd"
						placeholder="开始日期" 
						:picker-options="pickerStart">
					</el-date-picker>
					<el-date-picker 
						v-model="queryForm.endTime" 
						type="date" 
						value-format="yyyy-MM-dd"
						placeholder="结束日期" 
						:picker-options="pickerEnd">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button icon="el-icon-search" @click="list" type="danger">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData">
			<el-table-column prop="title" label="标题" min-width="200"></el-table-column>
			<el-table-column prop="operator" label="操作员" min-width="200"></el-table-column>
			<el-table-column prop="type" label="操作类型" min-width="200"></el-table-column>
			<el-table-column prop="url" label="请求地址" min-width="200"></el-table-column>
			<el-table-column prop="result" label="操作结果" min-width="200"></el-table-column>
			<el-table-column prop="createTime" label="操作时间" min-width="200"></el-table-column>
		</el-table>

		<el-pagination background style="margin-top: 20px;" layout="total,prev, pager, next,jumper"
			@current-change="handleCurrentChange" :current-page="pageNo" :total="total">
		</el-pagination>

	</div>
</template>

<script>
	import {
		query
	} from "@/api/logger";
	import token from "@/token";

	export default {
		name: "Index",
		data() {
			return {
				nowUserID: token.getUser().id,
				queryForm: {
					type: '',
					startTime: '',
					endTime:''
				},
				typeList:[
					{
						id:'add',
						label:'新增',
					},
					{
						id:'edit',
						label:'编辑',
					},
					{
						id:'delete',
						label:'删除',
					},
					{
						id:'other',
						label:'其他',
					},
				],
				pickerStart:{
					disabledDate: (time) => {
						return this.pickDateStart(time)
					}
				},
				pickerEnd:{
					disabledDate: (time) => {
						return this.pickDateEnd(time)
					}
				},
				tableData: [],
				total: 0,
				pageNo: 1,
			}
		},
		mounted() {
			this.list();
		},
		methods: {
			//改变当前页
			handleCurrentChange(val) {
				this.pageNo = val;
				this.list()
			},
			//查询用户列表
			list() {
				let param = {
					type: this.queryForm.type,
					startTime: this.queryForm.startTime,
					endTime: this.queryForm.endTime,
					page: this.pageNo,
					pageSize: 10,
				}
				console.log(param)
				query(param).then(data => {
					this.tableData = data.data.list
					this.total = data.data.total
				}).catch(error => {
					this.$message.error(error)
				})
			},
			pickDateStart(time){
				if(this.queryForm.startTime){
					return time.getTime() > new Date(this.queryForm.endTime).getTime()
				}else{
					return false
				}
			},
			pickDateEnd(time){
				if(this.queryForm.endTime){ 
					return time.getTime() < new Date(this.queryForm.startTime).getTime()
				}else{
					return false
				}
			},
		}
	}
</script>

<style scoped>
</style>
